export const routes = [
    {
        path: '/dummyChart',
        name: 'dummy.chart',
        meta: { guard: 'verified', roles: "adm, sup" }
    },
    {
        path: '/dummyReport',
        name: 'dummy.report',
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]